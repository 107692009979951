import styled from 'styled-components'
import { easings } from '../../../styles/vars/easings.style'

export const FadeInWrapper = styled.div.attrs(props => ({
  style: {
    transitionDelay: `${props.delay}s`,
  },
}))`
  opacity: 0;
  transition: opacity 1s ${easings.modal.default};

  [data-inview='true'] & {
    opacity: 1;
  }
`
