import React from 'react'

// Components
import Container from '../components/Container'
import Hero from '../components/Hero'
import Footer from '../components/Footer'
import ScrollSection from '../components/ScrollSection'
import Seo from '../components/Seo'

const NotFoundPage = () => {
  return (
    <>
      <Seo title="404" description="Page not found" />
      <Container>
        <ScrollSection>
          <Hero title={`404 Page Not Found`} errorPage={true} />
        </ScrollSection>
        <ScrollSection>
          <Footer />
        </ScrollSection>
      </Container>
    </>
  )
}

export default NotFoundPage
