import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

// Components
import Container from '../Container'
import GetInTouch from '../GetInTouchButton'
import Grid from '../Grid'
import GridItem from '../GridItem'
import PageTransitionLink from '../PageTransitionLink'
import Logomark from '../svgs/LogoDots'
import { TextBody } from '../TextStyles'
import {
  FooterWrapper,
  FooterMain,
  FooterStart,
  FooterMark,
  FooterMenu,
  FooterMenuItem,
  FooterCopyright,
  FooterLogo,
  FooterLogoDesk,
  FooterWrap,
} from './index.style'

import AnimateFadeIn from '../animation/AnimateFadeIn'

// SVGs
import LogoMobile from '../svgs/LogoMobile'
import Logo from '../svgs/Logo'

const Footer = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulBlockMenu(filter: { title: { eq: "Footer Menu" } }) {
            edges {
              node {
                menuItem {
                  title
                  link
                }
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <FooterWrapper>
            <Container>
              <Grid>
                <GridItem tiny={3} tabletL={1}>
                  <FooterStart>
                    <AnimateFadeIn>
                      <FooterMark>
                        <Logomark responsive />
                      </FooterMark>
                    </AnimateFadeIn>
                  </FooterStart>
                </GridItem>

                <GridItem tiny={13} tabletL={15}>
                  <FooterMain>
                    <Grid tabletL={15}>
                      <GridItem tabletL={13} tabletLStart={2} deskOrder={1}>
                        <FooterWrap>
                          <FooterLogo>
                            <PageTransitionLink underline={true} to="/">
                              <LogoMobile />
                            </PageTransitionLink>
                          </FooterLogo>

                          <FooterLogoDesk>
                            <PageTransitionLink underline={true} to="/">
                              <Logo />
                            </PageTransitionLink>
                          </FooterLogoDesk>

                          <FooterMenu>
                            {data.allContentfulBlockMenu.edges[0].node.menuItem.map(
                              (item, key) => {
                                return item.link?.startsWith('/') ? (
                                  <FooterMenuItem key={key}>
                                    <AnimateFadeIn delay={0.2 + 0.1 * key}>
                                      <PageTransitionLink
                                        underline={true}
                                        to={item.link}
                                        key={key}
                                      >
                                        {item.title}
                                      </PageTransitionLink>
                                    </AnimateFadeIn>
                                  </FooterMenuItem>
                                ) : (
                                  <FooterMenuItem key={key}>
                                    <AnimateFadeIn delay={0.2 + 0.1 * key}>
                                      <a href={item.link} key={key} underline={true}>
                                        {item.title}
                                      </a>
                                    </AnimateFadeIn>
                                  </FooterMenuItem>
                                )
                              }
                            )}

                            <FooterMenuItem>
                              <AnimateFadeIn delay={0.5}>
                                <GetInTouch hasborder narrow underline={true}/>
                              </AnimateFadeIn>
                            </FooterMenuItem>
                          </FooterMenu>
                        </FooterWrap>
                      </GridItem>

                      <GridItem tabletL={13} tabletLStart={2} deskOrder={3}>
                        <AnimateFadeIn delay={1}>
                          <FooterCopyright>
                            <TextBody>
                              © Hume {new Date().getFullYear()}. All rights
                              reserved
                            </TextBody>
                          </FooterCopyright>
                        </AnimateFadeIn>
                      </GridItem>
                    </Grid>
                  </FooterMain>
                </GridItem>
              </Grid>
            </Container>
          </FooterWrapper>
        )
      }}
    />
  )
}

export default Footer
