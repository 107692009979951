import React from 'react'

const BackButton = () => {
  return (
    <svg width='32px' height='33px' viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1.5" y="2" width="29" height="29" rx="14.5" stroke="#353535" strokeWidth="3"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M12.1389 17.7505L16.0154 21.627C16.5035 22.1151 16.5035 22.9066 16.0154 23.3948C15.5272 23.8829 14.7358 23.8829 14.2476 23.3948L8.23721 17.3843C7.74905 16.8962 7.74905 16.1047 8.23721 15.6166L8.25036 15.6036L14.2478 9.60617C14.736 9.11801 15.5274 9.11801 16.0156 9.60617C16.5037 10.0943 16.5037 10.8858 16.0156 11.3739L12.139 15.2505H22.8809C23.5712 15.2505 24.1309 15.8101 24.1309 16.5005C24.1309 17.1909 23.5712 17.7505 22.8809 17.7505H12.1389Z" fill="#353535"/>
    </svg>
  )
}
export default BackButton