import React from 'react'
import PropTypes from 'prop-types'
import { FadeInWrapper } from './index.style'

const AnimateFadeIn = ({ delay, children }) => {
  return <FadeInWrapper delay={delay}>{children}</FadeInWrapper>
}

AnimateFadeIn.propTypes = {
  children: PropTypes.node,
  delay: PropTypes.number,
}

AnimateFadeIn.defaultProps = {
  delay: 0,
}

export default AnimateFadeIn
