import styled from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { colors } from '../../styles/vars/colors.style'
import { mq } from '../../styles/vars/media-queries.style'

export const FooterWrapper = styled.footer`
  /* border-top: 0.1rem solid ${colors.black}; */
  position: relative;

  &:before {
    background-color: ${colors.dark};
    content: '';
    height: 0.1rem;
    left: -2.2rem;
    opacity: 0.3;
    position: absolute;
    right: -2.2rem;
    top: 0;

    ${mq.tabletL} {
      left: 0;
      right: 0;
    }
  }
`

export const FooterMain = styled.div`
  border-left: 0.1rem solid rgba(53, 53, 53, 0.3);
`

export const FooterStart = styled.div`
  height: 100%;
  ${clamp('padding-top', 26, 51)}
`

export const FooterMark = styled.div`
  ${clamp('width', 28, 42)}
  margin-top: 2rem;

  ${mq.tabletL} {
    margin-left: 2vw;
    margin-top: 0;
  }
`

export const FooterMenu = styled.ul`
  display: flex;
  flex-direction: column;
  ${clamp('padding-top', 26, 51)}
  ${clamp('padding-bottom', 26, 51)}
  padding-left: 3rem;
  list-style: none;

  ${mq.tabletL} {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0;
  }
`

export const FooterMenuItem = styled.li`
  display: inline-block;

  & + & {
    margin-top: 1.2rem;

    ${mq.tabletL} {
      margin-top: 0;
    }
  }

  ${mq.desk} {
    a {
      margin-right: 2rem;
    }
  }

  a,
  button {
    cursor: pointer;
    display: block;
    font-size: 1.6rem;
    text-transform: uppercase;
    width: fit-content;
  }

  button {
    span {
      border-bottom: 0;
      font-weight: 400;
    }
  }
`

export const FooterWrap = styled.div`
  ${mq.tabletL} {
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`

export const FooterLogo = styled.div`
  height: 4.5rem;
  margin-bottom: 9.3rem;
  margin-top: 4.3rem;
  padding-left: 3rem;
  position: relative;

  ${mq.tabletL} {
    display: none;
  }

  a {
    &:after {
      display: none;
    }
  }
`

export const FooterLogoDesk = styled.div`
  display: none;
  position: relative;

  ${mq.tabletL} {
    display: inline-block;

    a {
      padding-left: 0;

      &:after {
        display: none;
      }
    }
  }
`

export const FooterCopyright = styled.div`
  margin-bottom: 2rem;
  opacity: 0.3;
  padding-left: 3rem;

  ${mq.tabletL} {
    padding-left: 0rem;
  }

  p {
    font-size: 1.4rem;
  }
`
