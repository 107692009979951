import React, { useContext, useEffect, useState } from 'react'

import { ScreenContext } from "../ReformProviders/Providers";
import { LastPageContext } from "../ReformProviders/Providers";


import BackArrowSVG from '../svgs/BackArrow/index'
import PageTransitionLink from '../PageTransitionLink'


const BackArrow = () => {

  const { mobile } = useContext(ScreenContext);
  const { lastPageURL } = useContext(LastPageContext);
  const [lastPage, setLastPage] = useState('/');

  useEffect(() => {
    if (window.location.pathname === '/guidelines' || 
    window.location.pathname === '/about') {
      setLastPage('/')
    }
    else if (lastPageURL !== window.location.pathname) {
      setLastPage(lastPageURL)
    }
  }, [lastPageURL])

  return (
    <>
      {!mobile &&
        <PageTransitionLink to={lastPage} underline={false}>
            <BackArrowSVG/> 
        </PageTransitionLink>
      }
    </>
  )
}

export default BackArrow;